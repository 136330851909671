<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            outlined
                                            dense
                                            v-model="date_from"
                                            label="Last Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-clipboard-account"
                                        clearable
                                        dense
                                        solo
                                        v-model="sales"
                                        :items="saless"
                                        item-value="salesman_id"
                                        :item-text="item => item.salesman_name"
                                        label="Sales"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_sales"
                                        @change="(event) => getCustomer(event)"
                                        :disabled="sales_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-account-tie"
                                        clearable
                                        dense
                                        solo
                                        v-model="customer"
                                        :items="customers"
                                        item-value="cust_id"
                                        :item-text="item => item.cust_name"
                                        label="Customer"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_customer"
                                        @change="(event) => getConsignee(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="4" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-map-marker"
                                        clearable
                                        dense
                                        solo
                                        v-model="consigne"
                                        :items="consignes"
                                        item-value="cons_id"
                                        :item-text="item => item.cons_id.trim() + ' | ' + item.cons_name + ' | ' + item.address1"
                                        label="Consignee"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_consigne"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="3" lg="2">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="histories"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                    group-by="cust_name"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List History </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn
                                                small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                >
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :data="histori_exports"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                    </download-excel>
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.alamat`]="{ item }">
                                        {{ item.cons_name }} - {{ item.city }},  {{ item.address1 }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="800px"
                    hide-overlay
                    transition="dialog-bottom-transition"
                    style="border-radius: 0 !important"
                >
                    <v-card class="p-2" style="border-radius: 0 !important">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-5">
                                            Detail
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-2"
                                            fab
                                            x-small
                                            color="#e83e8c;"
                                            @click="[dialog = false]"
                                        >
                                            <v-icon dark>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-card outlined>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col class="col-6" sm="6" md="6">
                                                    Region : {{ detail ? detail.region : '' }}
                                                </v-col>
                                                <v-col class="col-6" sm="6" md="6">
                                                    Office : {{ detail ? detail.office : '' }}
                                                </v-col>
                                                <v-col class="col-6" sm="6" md="6">
                                                    Division : {{ detail ? detail.division_name : '' }}
                                                </v-col>
                                                <v-col class="col-6" sm="6" md="6">
                                                    Sales : {{ detail ? detail.NamaSales : '' }}
                                                </v-col>
                                                <v-col class="col-12" sm="12" md="12">
                                                    Customer : {{ detail ? detail.NamaCustomer : '' }}
                                                </v-col>
                                                <v-col class="col-12">
                                                    Alamat : {{ detail ? detail.cons_name : '' }} - {{ detail ? detail.alamat : '' }}
                                                </v-col>
                                            </v-row>
                    
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                                <v-card outlined class="mt-3">
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-data-table
                                                        dense
                                                        :headers="header_detail_histories"
                                                        :items="detail_histories"
                                                        class="elevation-1"
                                                        :items-per-page="30"
                                                        :footer-props="{
                                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                                        }"
                                                        :loading="$store.state.overlay"
                                                        height="350"
                                                        fixed-header
                                                        :divider="true"
                                                        :light="true"
                                                        :search="searchItem" 
                                                        :item-class="tr_datatable"
                                                        group-by="periode"
                                                    >    
                                                        <template v-slot:top>
                                                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                                                <div class="d-flex w-100">
                                                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Detail </h6>
                                                                    <v-spacer></v-spacer>
                                                                    <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                </div>
                                                            </v-toolbar>
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            search:'',
            breadcumbs: [
                {
                    text: 'Sunrise Mill',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Order',
                    disabled: true,
                },
                {
                    text: 'Customer History',
                    disabled: true,
                }
            ],
            regions: [],
            region: '',
            offices: [],
            office: '',
            saless: [],
            sales: '',
            customers: [],
            customer: '',
            consignes: [],
            consigne: '',
            divisions: [],
            division: '',
            modal: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            searchItemTrans: '',
            histories: [],
            headers: [],
            months: [],
            loading_office: false,
            loading_sales: false,
            loading_customer: false,
            loading_consigne: false,
            region_disabled: false,
            office_disabled: false,
            sales_disabled: false,
            division_disabled: false,
            histori_exports: [],
            dialog: false,
            detail: null,
            detail_histories: [],
            header_detail_histories:[
                { text: 'Periode', value: 'periode', width:150, align: 'left' },
                { text: 'Product Code', value: 'BarangJadiId', width:150, align: 'left' },
                { text: 'Product', value: 'NamaBarang', width:250, align: 'left' },
                { text: 'Berat', value: 'berat', width:120, align: 'right' }
            ],
            searchItem: ''
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  
        this.getMonth(this.date_from, 12)
        await this.getEnvConf()
        await this.getSales()
        // await this.getDivision()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        clear(){
            if (this.region_disabled == false) {
                this.region = ''
            }
            if (this.office_disabled == false) {
                this.office = ''
            }
            if (this.sales_disabled == false) {
                this.sales = ''
            }
            if (this.division_disabled == false) {
                this.division = ''
            }
            this.customer = ''
            this.consigne = ''
        },
        async getEnvConf(){

            const respDataSales = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=SM&appl_id=WEBSR&var_id=SALESID&groupid=${this.$store.state.sr.group_id}`, null, false, false, false)  
            
            if (respDataSales.status == 200) {
                if (this.$store.state.sr.group_id == 'MANAGEMENT') {
                    this.sales = ''
                    this.sales_disabled = false
                    
                } else {
                    this.sales = respDataSales.data.data ? respDataSales.data.data : ''
                    this.sales_disabled = respDataSales.data.data ? true : false
                    if (this.sales) {
                        await this.getSales(this.sales)
                        await this.getCustomer(this.sales)
                    }
                }
                
            } else {
                this.sales = ''
                this.sales_disabled = false
            }
        },
        async getDivision(){
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/division`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.divisions = res.data
            });
        },
        async getRegion(){
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/region?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.regions = res.data
                if (this.sales_disabled || this.office_disabled) {
                    this.region = this.regions.length > 0 ? this.regions[0].region : ''
                    this.region_disabled = true
                }
            });
        },
        async getOffice(event){
            if (event == '' || event == null) {
                this.office = ''
                this.region = ''
            } else {
                this.loading_office = true
                await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/office?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_office = false
                    this.offices = res.data
                });
            }
        },
        async getSales(){
            this.loading_sales = true
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/sales?salesid=${this.sales ? this.sales : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_sales = false
                this.saless = res.data
            });
        },
        async getCustomer(event){
            if (event == '' || event == null) {
                this.sales = ''
                this.customer = ''
            } else {
                this.loading_customer = true

                await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/CustOrder?txtSalesman=${this.sales}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_customer = false
                    this.customers = res.data
                });
            }
        },
        async getConsignee(event){
            if (event == '' || event == null) {
                this.customer = ''
                this.consigne = ''
            } else {
                this.loading_consigne = true
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/sm/consignee?customerid=${this.customer ? this.customer : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_consigne = false
                    this.consignes = res.data
                });
            }
        },
        getMonth(start, value){
            this.months = []
            this.headers = []
            const monthString = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            for (let index = 0; index <= value; index++) {
                if (index == 0) {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    let name = monthString[startDate.getMonth()];
                    this.months.push({
                        value: start.replace(/-/g,""),
                        text: start
                    })
                } else {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    var startDayofMonth = new Date(startDate.getFullYear(), (startDate.getMonth() + 1) - index, 0);
                    // console.log(startDayofMonth);
                    var month = (new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
                    let name = monthString[(new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).getMonth()];

                    this.months.push(
                        {
                            value: month.replace(/-/g,""),
                            text: month
                        }    
                    )
                }                  

            }

            this.headers = [
                { text: 'Customer', value: 'cust_name', width:300, align: 'left' },
                { text: 'Consignee', value: 'alamat', width:180, align: 'left', groupable:false, class: 'my-border' },
                { text: 'Commodity', value: 'commodity_id', width:180, align: 'left', groupable:false, class: 'my-border' },
                { text: this.months[0].text, value: this.months[0].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[1].text, value: this.months[1].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[2].text, value: this.months[2].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[3].text, value: this.months[3].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[4].text, value: this.months[4].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[5].text, value: this.months[5].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[6].text, value: this.months[6].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[7].text, value: this.months[7].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[8].text, value: this.months[8].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[9].text, value: this.months[9].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[10].text, value: this.months[10].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[11].text, value: this.months[11].value, width:100, align: 'right', groupable:false, class: 'my-border' }
            ]


        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            this.getMonth(this.date_from, 12)

            var reqBody = {
                'SalesId': this.sales ? this.sales : '',
                'CustomerId': this.customer ? this.customer : '',
                'cons_id': this.consigne ? this.consigne : '',
                'startPeriod': this.months.length > 0 ? this.months[11].value : '',
                'endPeriod': this.months.length > 0 ? this.months[0].value : '',
                'months' : this.months,
            }

            const respData = await backendApi.fetchCore('/api/v3/sm/order-by-consignee', reqBody, false, false, false)
            if (respData.status === 200) {
                console.log(respData.data);
                this.histories = respData.data
                this.$store.dispatch('setOverlay', false)  
            }

        },
        async exportExcel(){
            this.getMonth(this.date_from, 12)

            var reqBody = {
                'SalesId': this.sales ? this.sales : '',
                'CustomerId': this.customer ? this.customer : '',
                'cons_id': this.consigne ? this.consigne : '',
                'startPeriod': this.months.length > 0 ? this.months[11].value : '',
                'endPeriod': this.months.length > 0 ? this.months[0].value : '',
                'months' : this.months
            }

            const respData = await backendApi.fetchCore('/api/v3/sm/order-by-consignee', reqBody, false, false, false)
            if (respData.status === 200) {
                this.histori_exports = respData.data

                return this.histori_exports
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },    
}
</script>